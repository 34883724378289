import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// WaterfallChart.tsx
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Import the Highcharts More module
import HighchartsExporting from 'highcharts/modules/exporting'; // Optional: for exporting charts
import HighchartsExportData from 'highcharts/modules/export-data'; // Optional: for exporting data
import NoDataFound from '../NoDataFound/NoDataFound';
// Initialize the modules
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
var RecruitmentTimeline = function (props) {
    var recruitmentTimelineData = props.recruitmentTimelineData;
    // const graphBarColor = (value: number) => {
    //   if (value < 0) {
    //     return '#E31A1C';
    //   } else {
    //     return '#2b6fea';
    //   }
    // };
    var graphBarColor = function (label) {
        if (label === 'Total') {
            return 'black';
        }
        else if (label === 'Search' || label === 'Submit' || label === 'Accept') {
            return '#2b6fea';
        }
        else if (label === 'Interview' ||
            label === 'Offer' ||
            label === 'Start') {
            return '#a6a6a6';
        }
        else {
            return '#2b6fea';
        }
    };
    var options = {
        accessibility: {
            enabled: false, // Enables accessibility features
        },
        chart: {
            type: 'waterfall',
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false, // Removes the Highcharts watermark
        },
        legend: {
            enabled: false, // Hides the legend
        },
        exporting: {
            enabled: false, // Disable the export menu (hamburger menu)
        },
        xAxis: {
            categories: recruitmentTimelineData === null || recruitmentTimelineData === void 0 ? void 0 : recruitmentTimelineData.map(function (item) { return item.label; }),
            title: {
                text: null,
            },
        },
        yAxis: {
            title: {
                text: '# of Days',
            },
        },
        tooltip: {
            useHTML: true,
            backgroundColor: '#f7f7f7',
            borderColor: '#aaa',
            borderRadius: 5,
            style: {
                color: '#333', // Custom text color
            },
            shadow: true, // Adds shadow to tooltip for better visibility
        },
        plotOptions: {
            waterfall: {
                borderRadius: 0,
                borderWidth: 0, // Removes the border color
            },
            series: {
                dataLabels: {
                    enabled: true,
                    // useHTML: true,
                },
            },
        },
        series: [
            {
                type: 'waterfall',
                name: '# of days from previous step',
                data: recruitmentTimelineData === null || recruitmentTimelineData === void 0 ? void 0 : recruitmentTimelineData.map(function (item) { return ({
                    name: item.label,
                    y: item.value,
                    isSum: item.label === 'TOTAL' || item.label === 'Total',
                    color: graphBarColor(item.label), // Custom color based on the label
                }); }),
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                },
            },
        ],
    };
    return (_jsx(_Fragment, { children: recruitmentTimelineData && recruitmentTimelineData.length > 0 ? (_jsx(HighchartsReact, { highcharts: Highcharts, options: options })) : (_jsx(NoDataFound, {})) }));
};
export default RecruitmentTimeline;
