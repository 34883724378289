import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataFound from '../NoDataFound/NoDataFound';
var PlacedPositions = function (props) {
    var _a, _b, _c, _d;
    var placedPositionsMetrics = props.placedPositionsMetrics;
    // Extract data for Highcharts configuration
    var categories = (_a = placedPositionsMetrics === null || placedPositionsMetrics === void 0 ? void 0 : placedPositionsMetrics.data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.monthCreated; });
    var submittedData = (_b = placedPositionsMetrics === null || placedPositionsMetrics === void 0 ? void 0 : placedPositionsMetrics.data) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.positions; });
    var placementsData = (_c = placedPositionsMetrics === null || placedPositionsMetrics === void 0 ? void 0 : placedPositionsMetrics.data) === null || _c === void 0 ? void 0 : _c.map(function (item) { return item.placements; });
    var filledPercentData = (_d = placedPositionsMetrics === null || placedPositionsMetrics === void 0 ? void 0 : placedPositionsMetrics.data) === null || _d === void 0 ? void 0 : _d.map(function (item) { return item.filledPercentage; });
    // Highcharts configuration
    var options = {
        accessibility: {
            enabled: false, // Enables accessibility features
        },
        chart: {
            zooming: {
                type: 'xy',
            },
        },
        title: {
            text: '',
        },
        xAxis: [
            {
                categories: categories,
                crosshair: true,
            },
        ],
        yAxis: [
            {
                // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: '',
                },
            },
            {
                // Secondary yAxis for percentage
                title: {
                    text: '',
                },
                labels: {
                    format: '{value}%',
                },
                opposite: true,
            },
        ],
        tooltip: {
            shared: true,
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            backgroundColor: '#FFFFFF',
            symbolHeight: 10,
            symbolWidth: 10,
            symbolRadius: 5,
        },
        credits: {
            enabled: false, // Removes the Highcharts watermark
        },
        exporting: {
            enabled: false, // Disable the export menu (hamburger menu)
        },
        plotOptions: {
            column: {
                borderRadius: 0, // Remove rounded corners for sharp edges
            },
        },
        series: [
            {
                name: 'Positions',
                type: 'column',
                yAxis: 0,
                data: submittedData,
                tooltip: {
                    valueSuffix: '',
                },
                color: '#2A70EA',
            },
            {
                name: 'Placements',
                type: 'column',
                yAxis: 0,
                data: placementsData,
                tooltip: {
                    valueSuffix: '',
                },
                color: '#DDE3EE',
            },
            {
                name: '% Filled',
                type: 'line',
                yAxis: 1,
                data: filledPercentData,
                tooltip: {
                    valueSuffix: '%',
                },
                color: '#FF730E',
                marker: {
                    enabled: true,
                },
            },
        ],
    };
    return (_jsx(_Fragment, { children: placedPositionsMetrics && placedPositionsMetrics.data.length > 0 ? (_jsx(HighchartsReact, { highcharts: Highcharts, options: options })) : (_jsx(NoDataFound, {})) }));
};
export default PlacedPositions;
